import React, { useState, useEffect, useRef } from "react";
import ContentEditable from "react-contenteditable";
import shortid from "shortid";

import { strip, now } from "../helpers";

function Editor({
  database,
  current,
  setCurrent,
  setDbUpdated,
  sidebarActive
}) {
  const [content, setContent] = useState("");
  const editorEl = useRef();

  // Focus the editable area on load or on sidebar close
  useEffect(() => {
    if (!sidebarActive) editorEl.current.getEl().focus();
  }, [current, sidebarActive]);

  // Retrieve our note from the DB
  useEffect(() => {
    if (!current) {
      setContent("");
      return;
    } // return early

    database.get(current).then(note => {
      note && setContent(note.content);
    });
  }, [current, database]);

  // Save our note to DB
  useEffect(() => {
    if (!current) return; // return early

    // If the content has changed and the note has an associated ID, save it
    database.set(current, { content }).then(() => {
      setDbUpdated(now());
    });
  }, [content, current, database, setDbUpdated]);

  // Generate an ID for our note
  useEffect(() => {
    if (current) return; // return early

    const textValue = strip(content);

    // If the user has written at least 5 words of a new note generate an ID
    if (textValue.trim().length >= 1 && current === null) {
      setCurrent(shortid.generate());
    }
  }, [content, current, setCurrent]);

  return (
    <div className="editor">
      {content === "" ? (
        <div className="placeholder">Start typing...</div>
      ) : null}
      <ContentEditable
        ref={editorEl}
        className="editable"
        html={content} // innerHTML of the editable div
        onChange={e =>
          setContent(strip(e.target.value) === "" ? "" : e.target.value)
        } // handle innerHTML change
        //onChange={noop}
        tagName="article" // Use a custom HTML tag (uses a div by default)
      />
    </div>
  );
}

export default Editor;
