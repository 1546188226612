import React, { useState } from "react";
import ReactDOM from "react-dom";

import database from "./database";
import Editor from "./components/Editor";
import Sidebar from "./components/Sidebar";
import Newsletter from "./components/Newsletter";
import { now } from "./helpers";

import "./styles.css";

// Because yes
window.history.replaceState({}, null, "/write");

// Init Database
database.initialize();

function App() {
  const [current, setCurrent] = useState(null);
  const [dbUpdated, setDbUpdated] = useState(now());
  const [sidebarActive, setSidebarActive] = useState(false);

  return (
    <div className="App" onClick={() => setSidebarActive(false)}>
      <Editor
        database={database}
        current={current}
        setCurrent={setCurrent}
        setDbUpdated={setDbUpdated}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <Sidebar
        database={database}
        current={current}
        setCurrent={setCurrent}
        dbUpdated={dbUpdated}
        sidebarActive={sidebarActive}
        setSidebarActive={setSidebarActive}
      />
      <Newsletter />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
