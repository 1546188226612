import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { throttle } from "throttle-debounce";

// const noop = () => {};

export default function useMouseLeave(ref /*, effect = noop*/) {
  const [mouseLeft, setMouseLeft] = useState(true);

  const handleMouseEnter = useRef(() => {
    window.addEventListener("mousemove", handleMouseMove);
  }).current;

  const handleMouseMove = useRef(
    throttle(50, e => {
      const rect = ref.current.getBoundingClientRect();

      if (
        e.clientX < rect.left ||
        e.clientX > rect.right ||
        e.clientY < rect.top ||
        e.clientY > rect.bottom
      ) {
        setMouseLeft(true);
      } else {
        setMouseLeft(false);
      }
    })
  ).current;

  useLayoutEffect(() => {
    if (mouseLeft) {
      window.removeEventListener("mousemove", handleMouseMove);
      // effect(mouseLeft);
    }
  }, [mouseLeft]);

  useEffect(() => {
    const element = ref.current;
    element.addEventListener("mouseenter", handleMouseEnter);

    return () => {
      element.removeEventListener("mouseenter", handleMouseEnter);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return mouseLeft;
}
