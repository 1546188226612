import React from "react";

export default () => (
  <div className="newsletter">
    <small>
      Subscribe to the{" "}
      <a
        href="https://buttondown.email/poe?tags=Follower"
        rel="noopener"
        target="_blank"
      >
        newsletter
      </a>{" "}
      <span className="hide-on-mobile">for updates</span>
    </small>
  </div>
);
