import React, { useEffect, useState, useRef } from "react";
import useMouseLeave from "../hooks/useMouseLeave";

import { stripNl } from "../helpers";

function Sidebar({
  database,
  current,
  setCurrent,
  dbUpdated,
  sidebarActive,
  setSidebarActive
}) {
  const [notes, setNotes] = useState([]);
  const [triggerCanListen, setTriggerCanListen] = useState(true);

  const titleify = text => {
    const firstLine = stripNl(text)
      .split("\n")[0]
      .split(" ");
    if (firstLine.length <= 5) {
      return firstLine.join(" ");
    } else {
      return (
        firstLine
          .slice(0, 5)
          .join(" ")
          .replace(/[^a-zA-Z0-9]+$/, "") + "…"
      );
    }
  };

  useEffect(() => {
    database.all().then(notes => {
      setNotes(notes);
    });
  }, [database, dbUpdated]);

  useEffect(() => {
    if (sidebarActive) sidebarRef.current.focus();
  }, [sidebarActive]);

  //////////////

  const sidebarRef = useRef();

  const mouseLeft = useMouseLeave(sidebarRef);

  useEffect(() => {
    if (mouseLeft) {
      setSidebarActive(false);
      setTimeout(() => setTriggerCanListen(true), 200);
    }
  }, [mouseLeft]);

  return (
    <>
      <div
        className={`sidebar-trigger ${
          sidebarActive ? "sidebar-is-active" : ""
        }`}
        onMouseEnter={() => {
          if (triggerCanListen) setSidebarActive(true);
          setTriggerCanListen(false);
        }}
        onTouchEnd={e => {
          e.preventDefault();
          setSidebarActive(true);
        }}
      />
      <div
        tabIndex="-1"
        ref={sidebarRef}
        className={`sidebar ${sidebarActive ? "is-active" : ""}`}
        onClick={e => {
          e.stopPropagation();
          setSidebarActive(false);
          setTimeout(() => setTriggerCanListen(true), 200);
        }}
      >
        <h2>Saved Notes</h2>
        {notes.map(note => (
          <p
            key={note.key}
            className={current === note.key ? "selected" : ""}
            onClick={e => {
              setCurrent(note.key);
            }}
          >
            {titleify(note.content)}
          </p>
        ))}
        <p className="new-note" onClick={e => setCurrent(null)}>
          <b>+ New note</b>
        </p>
      </div>
    </>
  );
}

export default Sidebar;
