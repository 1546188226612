/** SimpleIDB
 *  -> https://medium.com/@xon5/replacing-localstorage-with-indexeddb-2e11a759ff0c **/

const dbName = "Poe"; // Name of the DB
const storeNotes = "notes"; // Name of the store (table) that will hold the notes

const database = {
  initialize() {
    return new Promise((resolve, reject) => {
      let request = indexedDB.open(dbName);
      request.onupgradeneeded = function() {
        request.result.createObjectStore(storeNotes);
        resolve();
      };
      request.onerror = function() {
        reject(request.error);
      };
    });
  },

  all() {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(dbName);
      oRequest.onsuccess = function() {
        let db = oRequest.result;
        let tx = db.transaction(storeNotes, "readonly");
        let st = tx.objectStore(storeNotes);
        let items = [];

        tx.oncomplete = function() {
          resolve(items);
        };

        var cursorRequest = st.openCursor();

        cursorRequest.onerror = function(error) {
          reject(error);
        };

        cursorRequest.onsuccess = function(evt) {
          var cursor = evt.target.result;
          if (cursor) {
            items.push({ key: cursor.key, ...cursor.value });
            cursor.continue();
          }
        };
      };
      oRequest.onerror = function() {
        reject(oRequest.error);
      };
    });
  },

  get(key) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(dbName);
      oRequest.onsuccess = function() {
        let db = oRequest.result;
        let tx = db.transaction(storeNotes, "readonly");
        let st = tx.objectStore(storeNotes);
        let gRequest = st.get(key);
        gRequest.onsuccess = function() {
          resolve(gRequest.result);
        };
        gRequest.onerror = function() {
          reject(gRequest.error);
        };
      };
      oRequest.onerror = function() {
        reject(oRequest.error);
      };
    });
  },

  set(key, value) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(dbName);
      oRequest.onsuccess = function() {
        let db = oRequest.result;
        let tx = db.transaction(storeNotes, "readwrite");
        let st = tx.objectStore(storeNotes);
        let sRequest = st.put(value, key);
        sRequest.onsuccess = function() {
          resolve();
        };
        sRequest.onerror = function() {
          reject(sRequest.error);
        };
      };
      oRequest.onerror = function() {
        reject(oRequest.error);
      };
    });
  },

  remove(key) {
    return new Promise((resolve, reject) => {
      let oRequest = indexedDB.open(dbName);
      oRequest.onsuccess = function() {
        let db = oRequest.result;
        let tx = db.transaction(storeNotes, "readwrite");
        let st = tx.objectStore(storeNotes);
        let rRequest = st.delete(key);
        rRequest.onsuccess = function() {
          resolve();
        };
        rRequest.onerror = function() {
          reject(rRequest.error);
        };
      };
      oRequest.onerror = function() {
        reject(oRequest.error);
      };
    });
  }
};

export default database;
