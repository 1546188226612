export const strip = html => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

export const stripNl = html => {
  html = html.replace(/(<\/.*?>|<br\s?\/?>)/gm, "$1\n");
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.innerText || "";
};

export const now = () => window.performance.now();
